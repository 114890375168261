import React, { FC, useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PromoFormComponent from "@promo-platform/promo-form"
import { getUtmParameters } from "@promo-platform/utils"

import Layout from "components/Layout"
import Banner from "components/Banner"
import UniversalImage from "components/common/UniversalImage"
import DangerouslySetInnerHtml from "components/common/DangerouslySetInnerHtml"
import { parseBanner } from "components/Banner/parsers"

import { gtmService } from "services/gtmService"

import "@promo-platform/styles"
import "gatsby-theme-nurofen/src/templates/SignUpPage/SignUpPage.scss"
import "./SignUpPageOverride.scss"

interface ISignUpPageProps extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] }
  data: SignUpTypes.ISignUpData
}

const SignUpPage: FC<ISignUpPageProps> = ({
  pageContext: { breadcrumbs },
  data: {
    signUp: {
      signUpBanner,
      signUpHeroImage,
      signUpHeroImageAltText,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      ogImage,
      lang,
      promoId,
      promoUrl,
      thankyouTitle,
      thankyouDescription,
      redirectUrl,
      redirectTimeout,
      submenu,
      beforeFormText,
      consent,
      formType,
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => {
  useEffect(() => {
    if (redirectUrl) {
      const timeout = setTimeout(() => {
        window.location.href = redirectUrl
      }, redirectTimeout)

      return () => clearTimeout(timeout)
    }
  }, [redirectUrl])

  useEffect(() => {
    if (promoUrl && promoId) return
    const timeoutId = gtmService.emitGenerateLead(formType)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div className="sign-up-page">
        <Banner
          {...parseBanner(signUpBanner?.[0]?.properties)}
          bgBannerColor={
            signUpBanner?.[0]?.properties?.pageBannerBannerBgColor?.[0]
              ?.properties?.colorPicker?.label || "blue"
          }
          breadcrumbs={breadcrumbs}
        />

        <div className="rounded-container">
          <Container fluid>
            <Row>
              {signUpHeroImage ? (
                <Col md={7} sm={12}>
                  <UniversalImage
                    imageAlt={signUpHeroImageAltText}
                    img={signUpHeroImage}
                    wrapperClassName="sign-up-hero-image"
                    objectFitData={{ objectFit: "contain" }}
                  />
                </Col>
              ) : null}

              {thankyouTitle || thankyouDescription ? (
                <Col sm={12} className="sign-up-page__title-section">
                  {thankyouTitle ? (
                    <h2 className="sign-up-page__thankyou-title">
                      {thankyouTitle}
                    </h2>
                  ) : null}

                  {thankyouDescription ? (
                    <DangerouslySetInnerHtml
                      className="sign-up-page__thankyou-description"
                      html={thankyouDescription}
                    />
                  ) : null}
                </Col>
              ) : (
                <Col
                  sm={12}
                  md={{ span: 6, offset: 2 }}
                  className="sign-up-page__title-section"
                >
                  <h2 className="sign-up-page__title">{submenu}</h2>
                </Col>
              )}
              {promoId && promoUrl ? (
                <div className="promo-block">
                  <DangerouslySetInnerHtml
                    className="promo-block__description"
                    html={beforeFormText}
                  />
                  <PromoFormComponent
                    id={promoId}
                    url={promoUrl}
                    utmParameters={getUtmParameters()}
                  />
                  <DangerouslySetInnerHtml
                    className="promo-block__description"
                    html={consent}
                  />
                </div>
              ) : null}
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $pageBannerImageSize: Int = 2000
    $pageIdRegex: String
  ) {
    signUp(link: { eq: $link }, lang: { eq: $lang }) {
      link
      lang
      ...FragmentSeo
      signUpBanner {
        properties {
          ...FragmentBanner
        }
      }
      signUpHeroImageAltText
      signUpHeroImage {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 600, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileImage: gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 320, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      promoUrl
      promoId
      thankyouTitle
      thankyouDescription
      redirectUrl
      redirectTimeout
      submenu
      beforeFormText
      consent
      formType
    }
    pageLangs: allSignUp(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`

export default SignUpPage
